import React, { useEffect, useState } from 'react';
import { Upload, Button } from 'antd';
// request
import { uploadFile, removeFile } from 'requests/common';

/**
 * Single upload button
 */
const UploadButton = ({ title = 'Click to Upload', preview = false, defaultFilePath = '', onChange }) => {
    const [currentFile, setCurrentFile] = useState(null);
    const [resultPath, setResultPath] = useState('');

    useEffect(() => {
        setResultPath(defaultFilePath);
    }, [defaultFilePath]);

    const beforeUploadHandler = () => {
        return false;
    }

    const onChangeFile = async ({file, fileList}) => {
        if (fileList.length) {
            onUploadFile(file);
        } else {
            onRemoveFile();
        }
    }

    const onUploadFile = async (file) => {
        setCurrentFile(file);

        const formData = new FormData();

        formData.append('file[]', file);

        const response = await uploadFile(formData);
        setResultPath(response.path[0]);
        onChange(response.path[0]);
    }

    const onRemoveFile = async () => {
        setCurrentFile(null);
        await removeFile(resultPath);
        setResultPath('');
        onChange('');
    }


    return (
        <React.Fragment>
            {
                (resultPath && preview) ? (
                    <div class="upload-preview">
                        <img src={`${process.env.REACT_APP_ASSET_URL}${resultPath}`} class="upload-preview-img" />
                        <div>
                            <Button size='small' className='p-0 mt-8' danger type='link' onClick={onRemoveFile}>Remove</Button>
                        </div>
                    </div>
                ) : (
                    <Upload
                        name="file"
                        maxCount={1}
                        multiple={false}
                        beforeUpload={beforeUploadHandler}
                        onChange={onChangeFile}
                    >
                        <Button>{title}</Button>
                    </Upload>
                )
            }
        </React.Fragment>
    )
}

export default UploadButton;