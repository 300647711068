import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Row, Col } from 'antd';
import OverviewCardPlan from './OverviewCardPlan';
import OverviewCardOrders from './OverviewCardOrders';
import OverviewCardSumary from './OverViewCardSummary';
// css
import 'assets/styles/overview.scss';
// actions
import { getSubscriptionAction as getSubscription } from 'redux/actions/auth';

const { Title } = Typography;

const Overview = () => {
	const dispatch = useDispatch();

	const authUser = useSelector((state) => state.auth.authUser);

	useEffect(() => {
        async function getData() {
            try {
                await dispatch(getSubscription());
            } catch (error) {
                console.log(error);
            }
        }

        getData();
    }, []);
	
	return (
		<div className="overview-content">
			<div className="overview-header">
				<Title level={3}>Hello {authUser?.full_name},</Title>
				<Title level={2}>Welcome back 👋</Title>
			</div>
			<div className="overview-main">
				<Row gutter={[24, 24]}>
					<Col xs={24} sm={24} md={24} lg={16} xl={14}>
						<OverviewCardPlan />
					</Col>
					<Col xs={24} sm={24} md={24} lg={8} xl={10}>
						<OverviewCardOrders />
					</Col>
					{/* <Col xs={24} sm={24} md={24} lg={16} xl={14}>
						<OverviewCardSumary />
					</Col>
					<Col xs={24} sm={24} md={24} lg={8} xl={10}>
						<OverviewCardSumary />
					</Col> */}
				</Row>
			</div>
		</div>
	);
};

export default Overview;
