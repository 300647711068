import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Modal, Table, Button, Checkbox, Alert } from "antd";
import { parseQueryParams, stringifyQueryParams } from 'utils/url';
import PageTitle from 'components/PageTitle';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import WarehouseCreateForm from './WarehouseCreateForm';
import _ from 'lodash';
// requests
import { getWarehouses, createWarehouse, updateWarehouseDetail, deleteWarehouse, assignWarehouse } from 'requests/warehouse';
import { getServices } from 'requests/service';
import { getModules } from 'requests/module';

const WarehouseList = () => {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [records, setRecords] = useState([]);
    const [visibleCreateForm, setVisibleCreateForm] = useState(false);
    const [visibleUpdateForm, setVisibleUpdateForm] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [services, setServices] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const titles = [{ path: location.pathname, title: 'Warehouses' }];

    const columns = [
        {
            title: 'No.',
            render: (text, record, index) => (
                <div>{index + 1}</div>
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Owner',
            render: (text, record) => (
                <div>
                    <div><strong>{record.owner_name}</strong></div>
                    <div>{record.owner_phone}</div>
                </div>
            )
        },
        {
            title: 'Address',
            render: (text, record) => (
                <div>{record.address}, {record.city}, {record.state.name}, {record.pincode}, {record.country.name}</div>
            )
        },
        {
            title: 'Services',
            render: (record) => {
                const defaultValues = record?.shipment_service_warehouses?.map(item => item.user_service?.service_id);

                return (
                    <div>
                        <Checkbox.Group
                            options={services}
                            defaultValue={defaultValues}
                            onChange={(values) => onAssign(record.id, values)}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Actions',
            render: (record) => (
                <div>
                    <Button type="link" size="small" onClick={() => onClickEditButton(record)}>
                        <PencilAltIcon width={24} height={24} />
                    </Button>
                    <Button danger type="link" size="small" onClick={() => onDelete(record.id)}>
                        <TrashIcon width={24} height={24} />
                    </Button>
                </div>
            )
        }
    ];

    useEffect(() => {
        const getData = async () => {
            const query = parseQueryParams(location);
            getRecords(query);

            fetchServices();
        }

        getData();
    }, [location]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        console.log(pagination, filters, sorter, extra)

        let query = parseQueryParams(location);
        query = {
            ...query,
            page: pagination.current,
            per_page: pagination.pageSize,
        };

        if (sorter.order) {
            query = {
                ...query,
                order_by: sorter.field,
                order_type: sorter.order === 'ascend' ? 'asc' : 'desc'
            }
        } else {
            delete query.order_by;
            delete query.order_type;
        }

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query)
        });
    }

    const getRecords = async (query) => {
        try {
            setLoading(true);
            const response = await getWarehouses(query);
            setRecords(response.records);
            setPage(response.page);
            setPerPage(response.per_page);
            setTotalCount(response.total_records);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    }

    const fetchServices = async () => {
        const moduleResponse = await getModules({ type: 2 }); // get shipment modules
        const moduleIds = moduleResponse.records.map(module => module.id);

        let services = [];
        for (let i = 0; i < moduleIds.length; i++) {
            const serviceResponse = await getServices(moduleIds[i], { is_paginate: 0 });
            services = services.concat(serviceResponse.records);
        }
        services = services.filter(service => service.use_custom_warehouses).map(service => ({ label: service.name, value: service.id }));
        setServices(services);
    }

    const onCreateWarehouse = async (data) => {
        await createWarehouse(data);
        await getRecords({});
    }

    const onClickEditButton = (record) => {
        setCurrentRecord(record);
        setVisibleUpdateForm(true);
    }

    const onUpdateWarehouse = async (data) => {
        await updateWarehouseDetail(currentRecord.id, data);
        await getRecords({});
    }

    const onDelete = (id) => {
        Modal.confirm({
            title: 'Do you want to delete this warehouse?',
            onOk: async () => {
                await deleteWarehouse(id);
                await getRecords();
            }
        })
    }

    const onAssign = _.debounce(async (id, values) => {
        await assignWarehouse(id, { service_ids: values });
    }, 300);

    return (
        <div>
            <PageTitle titles={titles} />
            <Row className='mt-16 mb-16'>
                <Button type='primary' onClick={() => setVisibleCreateForm(true)}>Create new warehouse</Button>
            </Row>
            <Alert
                className="mb-16"
                message="Please make sure service is enabled before assign."
                type="info"
                showIcon
                closable
            />
            <Table
                loading={loading}
                columns={columns}
                dataSource={records}
                rowKey='id'
                onChange={onChangeTable}
                pagination={{
                    pageSize: perPage,
                    total: totalCount,
                    current: page,
                }}
            />
            <WarehouseCreateForm
                isOpen={visibleCreateForm}
                title="Create new warehouse"
                onSubmit={onCreateWarehouse}
                onClose={() => setVisibleCreateForm(false)}
            />
            {
                visibleUpdateForm && (
                    <WarehouseCreateForm
                        isOpen={true}
                        defaultValues={currentRecord}
                        title="Update warehouse"
                        onSubmit={onUpdateWarehouse}
                        onClose={() => setVisibleUpdateForm(false)}
                    />
                )
            }
        </div>
    )
}

export default WarehouseList;
