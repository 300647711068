import { Button, Col, DatePicker, Form, Row, Select, Table, Typography } from 'antd';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { toast } from 'react-toast';
import { getDashboardCustomers } from 'requests/notification.js';

function Rule({ value = {}, onChange, first, onRemove }) {
    const [valueInput, setValueInput] = useState({});

    const paymentStatus = useSelector((state) => state.config.pay_statuses);
    const fulfillmentStatus = useSelector((state) => state.config.fulfillment_statuses);

    const valuesOptionsOf = useMemo(
        () => ({
            payment_status: paymentStatus.map((e) => ({ value: e.value, label: e.display })),
            fulfillment_status: fulfillmentStatus.map((e) => ({ value: e.value, label: e.display })),
        }),
        [paymentStatus, fulfillmentStatus],
    );

    const ruleOptions = [
        {
            value: 'payment_status',
            label: 'Payment status',
            placeholder: 'Select payment status',
            type: 'select',
        },
        {
            value: 'fulfillment_status',
            label: 'Fulfillment status',
            placeholder: 'Select payment status',
            type: 'select',
        },
        // {
        //     value: 'products_in_cart',
        //     label: 'Products in cart',
        //     placeholder: 'Select products',
        //     type: 'select',
        // },
        {
            value: 'created_at',
            label: 'Created at',
            type: 'range',
        },
    ];

    const handleChange = (newValue) => {
        onChange?.({
            type: 'AND',
            ...value,
            ...newValue,
        });
    };

    const handleSelectFilter = (value, option) => {
        handleChange({ filter: value, values: [] });
        setValueInput({
            type: option.type,
            options: valuesOptionsOf[option.value],
            placeholder: option.placeholder,
        });
    };

    return (
        <div className="rule">
            <Row className="mb-8">
                <Col span={first ? 0 : 4} className="pr-8">
                    <Select
                        size="small"
                        value={value.type}
                        onChange={(type) => handleChange({ type })}
                        options={[{ value: 'AND' }, { value: 'OR' }]}
                        placeholder="AND/OR"
                    />
                </Col>
                <Col span={first ? 22 : 18}>
                    <Select
                        size="small"
                        placeholder="Select field"
                        options={ruleOptions}
                        optionLabelProp="label"
                        onChange={handleSelectFilter}
                    />
                </Col>
                <Col span={2} className="remove" onClick={onRemove}>
                    <AiOutlineCloseCircle />
                </Col>
            </Row>
            {value?.filter && (
                <Row>
                    {valueInput.type === 'select' ? (
                        <Select
                            mode="multiple"
                            value={value.values}
                            options={valueInput.options}
                            placeholder={valueInput.placeholder}
                            optionLabelProp="label"
                            onChange={(values) => handleChange({ values })}
                        />
                    ) : (
                        <DatePicker.RangePicker
                            showTime
                            onChange={(values) =>
                                handleChange({ values: values.map((e) => e.utc?.()?.format?.('YYYY-MM-DD HH:mm:ss')) })
                            }
                        />
                    )}
                </Row>
            )}
        </div>
    );
}

export default function DashboardAudience({ onResult }) {
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        onResult?.(null);
    }, []);

    const handleSubmit = async () => {
        try {
            setLoading(true);

            const values = await form.validateFields();
            const data = await getDashboardCustomers(values);

            const customers = [];
            for (const customer of data) {
                const availableAddresses = customer.addresses?.filter(e => e.mobile) || [];
                const address = availableAddresses.length ? availableAddresses.find((e) => e.is_default || e.first_name || e.last_name) : null;
                if (!address) continue;

                const mobile = address.mobile.startsWith('+')
                    ? address.mobile
                    : address.country?.mobile_code + address.mobile.trimStart('0');
                const customer_name = `${address.first_name} ${address.last_name}`;
                const customer_city = address.city;
                const customer_country = address.country?.name;
                const customer_email = customer.email;

                customers.push({
                    mobile,
                    customer_email,
                    customer_name,
                    customer_city,
                    customer_country,
                });
            }

            setCustomers(customers);
            onResult?.(customers);
        } catch (err) {
            toast.error(err.response?.data.message || err.errorFields?.[0]?.errors?.[0] || err.message);
        } finally {
            setLoading(false);
        }
    };

    const rulesValidator = async (_, values) => {
        for (const rule of values || []) {
            if (!rule?.type) {
                throw Error('Type AND or OR is required');
            }
            if (!rule?.filter) {
                throw new Error('Filter is required');
            }
            if (!rule?.values?.length) {
                throw new Error('Provide at least one filter value');
            }
        }
    };

    const columns = [
        { title: 'Name', dataIndex: 'customer_name' },
        { title: 'Mobile', dataIndex: 'mobile' },
    ];

    return (
        <div className="audience">
            <div className="rules">
                <Typography.Title level={5}>Customers having</Typography.Title>
                <Form form={form}>
                    <Form.List name="rules" rules={[{ validator: rulesValidator }]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Form.Item {...field}>
                                        <Rule first={!index} onRemove={() => remove(index)} />
                                    </Form.Item>
                                ))}
                                <Button type="dashed" onClick={() => add()}>
                                    Add a rule
                                </Button>
                                <Button loading={loading} onClick={handleSubmit} className="ml-8" type="primary">
                                    Get audience
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
            <div className="preview">
                {customers && (
                    <>
                        <Typography.Title level={5}>{customers.length} customers</Typography.Title>
                        <Table
                            dataSource={customers}
                            columns={columns}
                            pagination={false}
                            scroll={{ y: 480 }}
                            rowKey={() => uniqueId()}
                        />
                    </>
                )}
            </div>
        </div>
    );
}
