import React, { useState, useEffect, useRef } from 'react';
import { Spin, Table } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import TableBar from 'components/TableBar';
import FilterDrawer from './FilterDrawer';
import OrderPaymentStatusDropdown from 'components/OrderPaymentStatusDropdown';
import { parseQueryParams, stringifyQueryParams } from 'utils/url';
import { omitBy, isEmpty } from 'lodash';
import ReactJson from 'react-json-view';
// request
import { getTransactions } from 'requests/transaction';

const TransactionList = () => {
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [records, setRecords] = useState([]);
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filter, setFilter] = useState(null);

    const searchRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const titles = [{ path: location.pathname, title: 'Transactions' }];

    const columns = [
        {
            title: 'Txn Date',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => (
                <div>
                    <div>{new Date(text).toLocaleDateString('en-GB')}</div>
                    <div>{new Date(text).toLocaleTimeString('en-GB')}</div>
                </div>
            )
        },
        {
            title: 'Txn ID',
            key: 'tx_id',
            dataIndex: 'tx_id',
        },
        {
            title: 'Gateway',
            key: 'gateway',
            dataIndex: 'gateway',
            render: (text, record) => (
                <div>{record.payment_gateway?.name}</div>
            )
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text, record) => (
                <OrderPaymentStatusDropdown
                    orderId={record.id}
                    defaultValue={text}
                    readonly={true}
                />
            )
        },
        {
            title: 'Order ID',
            key: 'order_id',
            dataIndex: 'order_id',
            render: (text, record) => (
                <Link to={`/orders/${record.order_id}`}>{record.order ? record.order.platform_order_id : record.order_id}</Link>
            )
        },
        {
            title: 'Customer',
            render: (text, record) => {
                if (record.order) {
                    return (
                        <div>
                            <div className='weight-6'>{record.order.billing_person_name}</div>
                            <div className='size-12'>
                                <span>Email: </span><a href={`mailto:${record.order.email}`}>{record.order.email}</a>
                            </div>
                            <div className='size-12'>
                                <span>Phone: </span><a href={`tel:${record.order.billing_person_mobile}`}>{record.order.billing_person_mobile}</a>
                            </div>
                        </div>
                    )
                }

                return null;
            }
        },
        {
            title: 'Amount',
            key: 'total',
            dataIndex: 'total'
        }
    ];

    useEffect(() => {
        const query = parseQueryParams(location);
        setFilter(query);
        getRecords(query);
    }, [location]);

    const getRecords = async (query) => {
        try {
            setIsTableLoading(true);
            const response = await getTransactions(query);

            setRecords(response.records);
            setPage(response.page);
            setPerPage(response.per_page);
            setTotalCount(response.total_records);
        } catch (err) {
            console.log(err);
        } finally {
            setIsTableLoading(false);
        }
    };

    const onSearch = (keyword) => {
        let query = parseQueryParams(location);
        query = {
            ...query,
            page: 1,
            keyword: keyword,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const onChangeTable = (pagination) => {
        console.log(pagination);

        let query = parseQueryParams(location);
        query = {
            ...query,
            page: pagination.current,
            per_page: pagination.pageSize,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const onToggleFilter = () => {
        setIsShowFilter(!isShowFilter);
    };

    const onChangeFilter = (name, e, isMuilty = false) => {
        if (isMuilty) {
            setFilter((preState) => ({ ...preState, [name]: e.join(',') }));
        } else {
            setFilter((preState) => ({ ...preState, [name]: e }));
        }
    };

    const onSaveFilter = () => {
        const saveFilterData = omitBy(filter, isEmpty);
        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(saveFilterData),
        });
        onToggleFilter();
    };

    const onResetFilter = () => {
        setFilter({});

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams({}),
        });
        onToggleFilter();
    }

    return (
        <div>
            <PageTitle titles={titles} />
            <TableBar
                onSearch={onSearch}
                showFilter={true}
                placeholderInput="Search by transaction ID"
                inputRef={searchRef}
                onFilter={onToggleFilter}
                isActiveFilter={isShowFilter}
                filter={filter}
            />
            <Spin spinning={isTableLoading}>
                <Table
                    className='mt-24'
                    dataSource={records}
                    columns={columns}
                    onChange={onChangeTable}
                    rowKey={'id'}
                    pagination={{
                        pageSize: perPage,
                        total: totalCount,
                        current: page,
                    }}
                    expandable={{
                        expandedRowRender: (record) => {
                            let pgData = [];

                            if (record.history && record.history.length) {
                                pgData = record.history.filter(item => String(item).startsWith('Notify')).map(item => JSON.parse(item.replace('Notify', '').trim()))
                            }

                            return (
                                <ReactJson className="details-viewer" src={pgData} name="details" collapsed={false} />
                            )
                        }
                    }}
                />
            </Spin>
            <FilterDrawer
                visible={isShowFilter}
                onClose={onToggleFilter}
                onSaveFilter={onSaveFilter}
                filterData={filter}
                onChangeFilter={onChangeFilter}
                onResetFilter={onResetFilter}
            />
        </div>
    );
};

export default TransactionList;
