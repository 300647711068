import { Button, Form, Input, Select, Typography, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea.js';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { parse } from 'utils/whatsappMessageParser.js';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getWhatsappMessageTemplates } from 'requests/notification.js';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'requests/common';

const { Title } = Typography;

export default function WhatsappTemplate({ templateRef, variables, onChange, onFileUploaded }) {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [previewTemplate, setPreviewTemplate] = useState();
    const [components, setComponents] = useState({
        header: [],
        body: [],
        footer: [],
        buttons: [],
    });

    const configWhatsapp = useSelector((state) => state.auth.authUser.config?.notification?.whatsapp);

    const [form] = Form.useForm();

    useEffect(() => {
        getTemplateData();
    }, []);

    const getTemplateData = async () => {
        try {
            const data = await getWhatsappMessageTemplates({ status: 'APPROVED' });

            const templateData = data.data;
            for (const each of templateData) {
                each.value = JSON.stringify({
                    name: each.name,
                    language: {
                        code: each.language,
                    },
                });

                each.label = `${each.language}-${each.name}`;
            }

            setTemplates(templateData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (selectedTemplate) {
            form.setFieldsValue({
                template: selectedTemplate,
            });
        }

        for (const each of templates) {
            if (each.value === selectedTemplate) {
                const formFields = {};

                // check for variables in template
                for (const component of each.components) {
                    if (component.type === 'HEADER') {
                        if (component.format === 'TEXT') {
                            const variables = [...new Set(component.text.match(/\{\{\d+\}\}/g) || [])];
                            formFields.header = variables
                                .sort((a, b) => Number(a.slice(2, -2)) < Number(b.slice(2, -2)))
                                .map((e) => ({
                                    name: 'header.' + e,
                                    type: 'TEXT',
                                    label: e,
                                }));
                        } else {
                            formFields.header = [
                                {
                                    name: 'header',
                                    type: component.format,
                                    label: component.format,
                                },
                            ];
                        }
                    } else if (component.type === 'BODY') {
                        const variables = [...new Set(component.text.match(/\{\{\d+\}\}/g) || [])];
                        formFields.body = variables
                            .sort((a, b) => Number(a.slice(2, -2)) < Number(b.slice(2, -2)))
                            .map((e) => ({
                                name: 'body.' + e,
                                type: 'text',
                                label: e,
                            }));
                    } else if (component.type === 'FOOTER') {
                        const variables = [...new Set(component.text.match(/\{\{\d+\}\}/g) || [])];
                        formFields.footer = variables
                            .sort((a, b) => Number(a.slice(2, -2)) < Number(b.slice(2, -2)))
                            .map((e) => ({
                                name: 'footer.' + e,
                                type: 'text',
                                label: e,
                            }));
                    } else if (component.type === 'BUTTONS') {
                        const variables = [];

                        component.buttons.forEach((button, index) => {
                            if (button.type === 'URL') {
                                const urlVars = [...new Set(button.url?.match(/\{\{\d+\}\}/g) || [])];
                                variables.push(
                                    ...urlVars
                                        .sort((a, b) => Number(a.slice(2, -2)) < Number(b.slice(2, -2)))
                                        .map((e) => ({
                                            name: `button.${index}.${e}`,
                                            type: button.type,
                                            parameter_type: 'text',
                                            label: `(${button.url})`,
                                            index,
                                        })),
                                );

                                const textVars = [...new Set(button.text?.match(/\{\{\d+\}\}/g) || [])];
                                variables.push(
                                    ...textVars
                                        .sort((a, b) => Number(a.slice(2, -2)) < Number(b.slice(2, -2)))
                                        .map((e) => ({
                                            name: `button.${index}.${e}`,
                                            type: button.type,
                                            parameter_type: 'text',
                                            label: `(${button.text})`,
                                            index,
                                        })),
                                );
                            } else if (button.type === 'COPY_CODE') {
                                variables.push({
                                    name: `button.copy.${index}`,
                                    type: button.type,
                                    parameter_type: 'coupon_code',
                                    label: `(${button.text})`,
                                    index,
                                });
                            }
                        });

                        formFields.buttons = variables;
                    }
                }

                setPreviewTemplate(each);
                setComponents(formFields);
                break;
            }
        }
    }, [selectedTemplate, templates, form]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();

            const content = [];

            for (const part of ['header', 'body', 'footer']) {
                const variables = components[part];
                if (!variables?.length) continue;

                const component = {
                    type: part,
                    parameters: [],
                };
                for (const variable of variables) {
                    const type = variable.type.toLowerCase();
                    component.parameters.push({
                        type,
                        [type]: values[variable.name],
                    });
                }

                content.push(component);
            }

            const buttons = [];
            for (const variable of components.buttons || []) {
                if (!buttons[variable.index]) {
                    buttons[variable.index] = {
                        type: 'button',
                        sub_type: variable.type,
                        parameters: [],
                        index: String(variable.index),
                    };
                }

                if (variable.type === 'COPY_CODE') {
                    buttons[variable.index].parameters.push({
                        type: variable.parameter_type,
                        coupon_code: values[variable.name],
                    });
                } else {
                    buttons[variable.index].parameters.push({
                        type: variable.parameter_type,
                        text: values[variable.name],
                    });
                }
            }

            content.push(...buttons);

            onChange?.({
                ...JSON.parse(values.template),
                components: content,
            });
        } catch (err) {
            // toast.error("err.message");
            console.log(err);
        }
    };

    useEffect(() => {
        if (templateRef) {
            templateRef.current = { getTemplate: handleSubmit };
        }
    }, [templateRef, handleSubmit]);

    const handleFormChange = (values) => {
        if (values.template) {
            setSelectedTemplate(values.template);
            return;
        }

        setPreviewTemplate({ ...previewTemplate });
    };

    // map template into preview and pass parameters
    const preview = {};
    for (const component of previewTemplate?.components || []) {
        if (component.type === 'HEADER') {
            preview.header = {
                format: component.format,
                text: parse(component.text) || component.format,
            };

            if (component.format === 'IMAGE') {
                console.log(form.getFieldValue(components.header[0].name), components.header[0].name);
                preview.header.element = <img src={form.getFieldValue(components.header[0].name)?.link} width="100%" alt="" />;
            }
            if (component.format === 'VIDEO') {
                preview.header.element = <video src={form.getFieldValue(components.header[0].name)?.link} controls width="100%" alt="" />;
            }
            if (component.format === 'TEXT') {
                const variables = preview.header.text.match(/\{\{\d+\}\}/g) || [];
                for (const variable of variables) {
                    const value = form.getFieldValue('header.' + variable);
                    if (value) preview.header.text = preview.header.text.replace(variable, value);
                }
            }
        } else if (component.type === 'BODY') {
            preview.body = component.text;

            const variables = preview.body.match(/\{\{\d+\}\}/g) || [];
            for (const variable of variables) {
                const value = form.getFieldValue('body.' + variable);
                if (value) preview.body = preview.body.replace(variable, value);
            }

            preview.body = parse(preview.body);
        } else if (component.type === 'FOOTER') {
            preview.footer = component.text;

            const variables = preview.footer.match(/\{\{\d+\}\}/g) || [];
            for (const variable of variables) {
                const value = form.getFieldValue('footer.' + variable);
                if (value) preview.footer = preview.footer.replace(variable, value);
            }

            preview.footer = parse(preview.footer);
        } else if (component.type === 'BUTTONS') {
            preview.buttons = component.buttons.map((button, index) => {
                const variables = button.url?.match(/\{\{\d+\}\}/g) || [];
                let url = button.url;
                for (const variable of variables) {
                    const value = form.getFieldValue(`button.${index}.${variable}`);
                    if (value) url = url.replace(variable, value);
                }

                return {
                    text: button.text,
                    href: url || button.phone_number,
                };
            });
        }
    }

    return (
        <div className="edit-whatsapp-content">
            <Form form={form} onValuesChange={handleFormChange} layout="vertical">
                <div className="template-select mb-16">
                    <Form.Item
                        hidden={!configWhatsapp.has_account}
                        name="template"
                        rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Select
                            size="middle"
                            options={templates}
                            optionLabelProp="label"
                            placeholder="Select a template"
                        />
                    </Form.Item>
                </div>
                {selectedTemplate && (
                    <div className="edit-preview mt-16">
                        <div className="edit">
                            <Title level={4}>Edit template content</Title>
                            {variables?.length > 0 && (
                                <>
                                    <p>Following variables can be used for this template</p>
                                    {variables.map((e, i) => (
                                        <React.Fragment key={i}>
                                            {i !== 0 && <span>, </span>}
                                            <span className="variable">{`{{${e}}}`}</span>
                                        </React.Fragment>
                                    ))}
                                </>
                            )}
                            {components.header?.length > 0 && (
                                <>
                                    <Title level={5}>Header</Title>
                                    {components.header.map((e, i) => {
                                        switch (e.type) {
                                            case 'TEXT': {
                                                return (
                                                    <Form.Item
                                                        key={i}
                                                        name={e.name}
                                                        label={`Edit content for variable ${e.label}`}
                                                        rules={[{ required: true, message: 'This field is required' }]}
                                                    >
                                                        <Input placeholder="Type content here..." />
                                                    </Form.Item>
                                                );
                                            }
                                            case 'DOCUMENT':
                                            case 'VIDEO':
                                            case 'IMAGE': {
                                                return (
                                                    <Form.Item
                                                        key={i}
                                                        name={e.name}
                                                        label={`Edit content for variable ${e.label}`}
                                                        rules={[{ required: true, message: 'This field is required' }]}
                                                    >
                                                        <HeaderInput
                                                            onFileUploaded={onFileUploaded}
                                                            placeholder="Type URL for header media"
                                                        />
                                                    </Form.Item>
                                                );
                                            }
                                            default: {
                                                return <></>;
                                            }
                                        }
                                    })}
                                </>
                            )}
                            {components.body?.length > 0 && (
                                <>
                                    <Title level={5}>Body</Title>
                                    {components.body.map((e, i) => (
                                        <Form.Item
                                            key={i}
                                            name={e.name}
                                            label={`Edit content for variable ${e.label}`}
                                            rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <TextArea placeholder="Type content here..." />
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                            {components.footer?.length > 0 && (
                                <>
                                    <Title level={5}>Footer</Title>
                                    {components.body.map((e, i) => (
                                        <Form.Item
                                            key={i}
                                            name={e.name}
                                            label={`Edit content for variable ${e.label}`}
                                            rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Input placeholder="Type content here..." />
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                            {components.buttons?.length > 0 && (
                                <>
                                    <Title level={5}>Buttons</Title>
                                    {components.buttons.map((e, i) => (
                                        <Form.Item
                                            key={i}
                                            name={e.name}
                                            label={`Edit content for variable ${e.label}`}
                                            rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Input placeholder="Type content here..." />
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                            {/* <Button onClick={handleSubmit} type="primary">
                                Save
                            </Button> */}
                        </div>
                        {previewTemplate && (
                            <div className="preview">
                                <Title level={4}>Preview</Title>
                                <div className="preview-box">
                                    <div className="message">
                                        {preview.header && (
                                            <div className={`header ${preview.header.format}`}>
                                                {preview.header.element || HTMLReactParser(preview.header.text)}
                                            </div>
                                        )}
                                        {preview.body && <div className="body">{HTMLReactParser(preview.body)}</div>}
                                        {preview.footer && (
                                            <div className="footer">{HTMLReactParser(preview.footer)}</div>
                                        )}
                                        {preview.buttons && (
                                            <div className="buttons">
                                                {preview.buttons.map((e, i) => (
                                                    <a className="url-button" href={e.href} title={e.href} key={i}>
                                                        <FaExternalLinkAlt />
                                                        {e.text}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </div>
    );
}

const HeaderInput = ({ value, onChange, onClick, onFileUploaded, placeholder }) => {
    const handleChange = (e) => onChange?.({ link: e.target.value });

    const handleUpload = async ({ file, fileList }) => {
        if (fileList.length) {
            const formData = new FormData();

            formData.append('file[]', file.originFileObj);

            const response = await uploadFile(formData);

            onChange?.({ link: `${process.env.REACT_APP_ASSET_URL}${response?.path[0]}` });
            onFileUploaded?.(response?.path[0]);
        }
    };

    return (
        <div onClick={onClick}>
            <Input className="mb-12" placeholder={placeholder} value={value?.link} onChange={handleChange} />
            <Upload
                name="file"
                maxCount={1}
                beforeUpload={false}
                multiple={false}
                onChange={handleUpload}
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
        </div>
    );
};
