import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button, Collapse, Checkbox, DatePicker, Row } from 'antd';
import { useSelector } from 'react-redux';
import { CaretRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const { Panel } = Collapse;

const PanelHeader = ({ title }) => {
	return <div className="weight-6 size-16 gray-color">{title}</div>;
};

function FilterDrawer({ visible, onClose, onSaveFilter, filterData, onChangeFilter, onResetFilter }) {
	const payStatuses = useSelector((state) => state.config.pay_statuses);
	const serviceTypes = useSelector((state) => state.config.service_types.filter(service => service.type === 'payment'));

	const onFilterByDates = (dates, dateStrings) => {
		const selectedDateStrings = dateStrings.filter(date => date);

		selectedDateStrings.forEach((date, index) => {
			const value = dayjs(date).tz('UTC').format('YYYY-MM-DD HH:mm:ss');

			if (index === 0) onChangeFilter('created_at_min', value, false);
			else onChangeFilter('created_at_max', value, false);
		});
	}

	return (
		<Drawer className="filter-drawer" placement="right" onClose={onClose} visible={visible} closable={false}>
			<p className="weight-6 size-20 mb-18">Filter</p>
			<Collapse
				className="mt-8"
				bordered={false}
				expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
				expandIconPosition="right"
			>
				<Panel header={<PanelHeader title="Date" />} key="1">
					<DatePicker.RangePicker style={{ width: '100%', marginBottom: '8px' }} onChange={onFilterByDates} />
				</Panel>
				<Panel header={<PanelHeader title="Payment Status" />} key="2">
					<Checkbox.Group
						defaultValue={filterData?.payment_status || ['']}
						onChange={(e) => onChangeFilter('status', e, true)}
					>
						{payStatuses?.map((item) => (
							<div key={item.value}>
								<Checkbox value={item.value} className="mt-8 mb-8 size-16">
									{item.display}
								</Checkbox>
								<br />
							</div>
						))}
					</Checkbox.Group>
				</Panel>
				<Panel header={<PanelHeader title="Payment methods" />} key="3">
					<Checkbox.Group
						defaultValue={filterData?.payment_method || ['']}
						onChange={(e) => onChangeFilter('gateway', e, true)}
					>
						{serviceTypes?.map((item, index) => (
							<div key={index}>
								<Checkbox value={item.id} key={index} className="mt-8 mb-8 size-16">
									{item.display}
								</Checkbox>
								<br />
							</div>
						))}
					</Checkbox.Group>
				</Panel>
			</Collapse>
			<Row gutter={16}>
				<Button type="default" size="large" className="button-filter" onClick={onResetFilter}>
					Reset
				</Button>
				<Button type="primary" size="large" className="button-filter" onClick={onSaveFilter}>
					Save Filter
				</Button>
			</Row>
		</Drawer>
	);
}

FilterDrawer.propTypes = {
	onClose: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
	orders: PropTypes.object,
	onSaveFilter: PropTypes.func,
	filterData: PropTypes.object,
};

FilterDrawer.defaultProps = {
	orders: {},
	onSaveFilter: () => { },
	filterData: null,
};

export default FilterDrawer;
