import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row, Typography, Form, Button, Alert, Input, Result } from "antd";
import { toast } from 'react-toast';
// request
import { onboardServices, postOnboardServices } from 'requests/service';

const OnboardingServiceForm = (props) => {
    const { visible, onClose, serviceId, data, onlyIndian } = props;

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("Confirm your data");
    const [initialFormData, setInitialFormData] = useState({});
    const [requireMoreAction, setRequireMoreAction] = useState(false);
    const [postOnboardingFormFields, setPostOnboardingFormFields] = useState([]);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const formData = {};
        data.forEach(item => {
            formData[item.name] = item.value;
        });
        setInitialFormData(formData);
    }, [data]);

    const onOnboarding = async (data) => {
        try {
            setLoading(true);

            const response = await onboardServices({ service_id: serviceId, data: data });
            if (response.status) {
                if (response.action) {
                    setTitle("Action required");
                    setRequireMoreAction(true);
                    setPostOnboardingFormFields(response.formFields);
                } else {
                    setSuccess(true);
                    setTitle(null);
                }
            }
        } catch (err) {
            toast.error(err.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const onSubmit = async (data) => {
        try {
            setLoading(true);

            const response = await postOnboardServices({ service_id: serviceId, data: data });
            if (response.status) {
                setSuccess(true);
                setTitle(null);
            }
        } catch (err) {
            toast.error(err.response.data.message);
        } finally {
            setLoading(false);
        }
    }

    const onCancel = () => {
        onClose(success); // <=== when success, save config after close modal
        setTitle("Confirm your data");
        setRequireMoreAction(false);
        setPostOnboardingFormFields([]);
        setSuccess(false);
    }

    return (
        <Modal
            title={title}
            visible={visible}
            closable={true}
            onCancel={() => onCancel()}
            footer={null}
            width={1000}
        >
            {
                success ? (
                    <div>
                        <Result
                            status="success"
                            title="Successfully onboarded!"
                            subTitle="Now you can use all features of this service in Sellonboard dashboard!"
                            extra={[
                                <Button type="primary" key="console" onClick={() => onCancel()}>
                                    Close
                                </Button>
                            ]}
                        />
                    </div>
                ) : (
                    <div>
                        {
                            onlyIndian && (
                                <Alert
                                    type="warning"
                                    showIcon
                                    message="This service allows onboarding for Indian merchants only."
                                    className='mb-16'
                                />
                            )
                        }
                        {
                            requireMoreAction ? (
                                <Form
                                    layout='vertical'
                                    onFinish={onSubmit}
                                >
                                    <Row gutter={16}>
                                        {
                                            postOnboardingFormFields.map((item, index) => (
                                                <Col xs={24} sm={24} md={12} lg={12} key={index}>
                                                    <Form.Item name={item.name} label={item.label} rules={[{ required: item.required }]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>

                                    <Row justify='end'>
                                        <Button type="primary" htmlType='submit' loading={loading}>Submit</Button>
                                    </Row>
                                </Form>
                            ) : (
                                <Form
                                    layout='vertical'
                                    initialValues={initialFormData}
                                    onFinish={onOnboarding}
                                >
                                    <Row gutter={16}>
                                        {
                                            data.map((item, index) => (
                                                <Col xs={24} sm={24} md={12} lg={12} key={index}>
                                                    <Form.Item name={item.name} label={item.label} rules={[{ required: item.required }]}>
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>

                                    <Row justify='end'>
                                        <Button type="primary" htmlType='submit' loading={loading}>Onboarding</Button>
                                    </Row>
                                </Form>
                            )
                        }
                    </div>
                )
            }


        </Modal>
    )
}

export default OnboardingServiceForm;