import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Button, Row } from 'antd';
import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import TransactionRate from './TransactionRate';
import Channels from './Channels';
// styles
import 'assets/styles/payment.scss';
// requests
import { getModules } from 'requests/module';
import { getServices } from 'requests/service';
import { getPaymentSettings, savePaymentSettings } from 'requests/payment';

const titles = [
	{ path: '/payments', title: 'Payment Advanced Settings' },
];

const { TabPane } = Tabs;

function PaymentAdvancedSettings() {
	const [tab, setTab] = useState('rate');
	const [loading, setLoading] = useState(true);
	const [services, setServices] = useState([]);
	const [data, setData] = useState({});
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	useEffect(() => {
		const getData = async () => {
			const moduleResponse = await getModules({ type: 1 }); // get payment modules
			const moduleIds = moduleResponse.records.map(module => module.id);

			let services = [];
			for (let i = 0; i < moduleIds.length; i++) {
				const serviceResponse = await getServices(moduleIds[i], { is_paginate: 0 });
				services = services.concat(serviceResponse.records);
			}
			setServices(services);

			// get initial settings data
			const response = await getPaymentSettings();
			setData(response);
			setLoading(false);
		}

		getData();
	}, []);

	const onChangeTab = (value) => {
		setTab(value);
	}

	const updateData = (namePath, value) => {
		const newData = { ...data };
		const paths = namePath.split('.');

		let subData = newData;
		const length = paths.length;
		for (let i = 0; i < length - 1; i++) {
			let elem = paths[i];
			if (!subData[elem]) subData[elem] = {};
			subData = subData[elem];
		}
		subData[paths[length - 1]] = value;

		console.log(newData);

		setData(newData);
	}

	const onSubmit = async () => {
		try {
			setLoadingSubmit(true);
			await savePaymentSettings(data);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingSubmit(false);
		}
	}

	return (
		<div className="wrap-payment mb-36">
			<PageTitle titles={titles} />
			{
				loading ? (
					<Loading />
				) : (
					<div>
						{/* <Tabs defaultActiveKey={tab} size="large" onChange={(value) => onChangeTab(value)}>
							<TabPane
								tab='Transaction rate'
								key="rate"
							>
								<TransactionRate
									services={services}
									rates={data?.rate}
									onUpdateData={updateData}
								/>
							</TabPane>
							<TabPane
								tab='Channels'
								key="platform"
							>
								<Channels
									settings={data?.platform}
									onUpdateData={updateData}
								/>
							</TabPane>
						</Tabs> */}
						<Channels
							settings={data?.platform}
							onUpdateData={updateData}
						/>
						<Row justify='end' className='mt-32'>
							<Button onClick={onSubmit} size="large" type="primary" loading={loadingSubmit}>Save</Button>
						</Row>
					</div>
				)
			}
		</div>
	);
}

export default PaymentAdvancedSettings;
