import React, { useEffect, useState } from 'react';
import { Button, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'requests/common';

const WhatsappHeaderFileInput = ({ value, onChange, onClick, onFileUploaded, placeholder }) => {
    const handleChange = (e) => onChange?.({ link: e.target.value });

    const handleUpload = async ({ file, fileList }) => {
        if (fileList.length) {
            const formData = new FormData();

            formData.append('file[]', file.originFileObj);

            const response = await uploadFile(formData);

            onChange?.({ link: `${process.env.REACT_APP_ASSET_URL}${response?.path[0]}` });
            onFileUploaded?.(response?.path[0]);
        }
    };

    return (
        <div onClick={onClick}>
            <Input className="mb-12" placeholder={placeholder} value={value?.link} onChange={handleChange} />
            <Upload
                name="file"
                maxCount={1}
                beforeUpload={false}
                multiple={false}
                onChange={handleUpload}
                showUploadList={false}
            >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
        </div>
    );
};

export default WhatsappHeaderFileInput;