// config
export const GET_CONFIG = 'GET_CONFIG';
export const SET_CONFIG = 'SET_CONFIG';

// auth
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';