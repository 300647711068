import React, { useEffect, useState } from 'react';
import { Row, Space, Checkbox, List, Pagination } from 'antd';
import PropTypes from 'prop-types';
import OrderCard from 'components/OrderCard';

const OrderCardView = ({ records, isTableLoading, pagination, selectedRecords, onSelectRecords }) => {
	const [selectedOrders, setSelectedOrders] = useState([]);
	const [isSmallMobile, setIsSmallMobile] = useState(false);
	const [windowSize, setWindowSize] = useState(getWindowSize());

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	useEffect(() => {
		if (windowSize.innerWidth <= 767) {
			setIsSmallMobile(true)
		} else {
			setIsSmallMobile(false)
		}
	}, [windowSize.innerWidth])

	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}

	useEffect(() => {
		setSelectedOrders(selectedRecords);
	}, [selectedRecords]);

	const onToggleSelectOrder = (orderId) => {
		let newSelectedOrders = [...selectedRecords];
		if (newSelectedOrders.includes(orderId)) {
			// remove order
			newSelectedOrders = newSelectedOrders.filter(item => item !== orderId);
		} else {
			// add order
			newSelectedOrders.push(orderId);
		}

		onSelectRecords(newSelectedOrders);
	}

	return (
		<div>
			<List
				className='order-card-list'
				itemLayout="vertical"
				size='large'
				bordered
				loading={isTableLoading}
				dataSource={records}
				renderItem={item => (
					<List.Item>
						<OrderCard
							selected={selectedOrders.includes(item.id)}
							order={item}
							onToggleSelectOrder={onToggleSelectOrder}
							isSmallMobile={isSmallMobile}
						/>
					</List.Item>
				)}
			/>
			<Row justify='end' className='mt-24'>
				<Pagination {...pagination} />
			</Row>
		</div>
	)
}

OrderCardView.propTypes = {
	records: PropTypes.arrayOf(PropTypes.object).isRequired,
	isTableLoading: PropTypes.bool.isRequired,
	pagination: PropTypes.object.isRequired,
	onSelectOrders: PropTypes.func
};

export default OrderCardView;
