import api from 'utils/api';
import { toast } from 'react-toast';

/**
 * Warehouse API
 */

export const getWarehouses = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/merchant/warehouses', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const createWarehouse = (data) => {
	return new Promise((resolve, reject) => {
		return api.post('/merchant/warehouses', data).then((response) => {
			resolve(response.data);
		}).catch((err) => {
			reject(err);
		});
	});
};

export const getWarehouseDetail = (id) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/merchant/warehouses/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateWarehouseDetail = (id, data) => {
	return new Promise((resolve, reject) => {
		return api.put(`/merchant/warehouses/${id}`, data).then((response) => {
			toast.success("Update warehouse successfully");
			resolve(response.data);
		}).catch((err) => {
			toast.error(err.response.data.message);
			reject(err);
		});
	});
};

export const deleteWarehouse = (id) => {
	return new Promise((resolve, reject) => {
		return api.delete(`/merchant/warehouses/${id}`).then((response) => {
			toast.success("Success");
			resolve(response.data);
		}).catch((err) => {
			toast.error(err.response.data.message);
			reject(err);
		});
	});
};

export const assignWarehouse = (id, data) => {
	return new Promise((resolve, reject) => {
		return api.put(`/merchant/warehouses/${id}/assign`, data).then((response) => {
            toast.success("Success");
			resolve(response.data);
		}).catch((err) => {
			reject(err);
		});
	});
};
