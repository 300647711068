import { updateService } from 'requests/service';

export async function handleServiceSdkButton(serviceId, userServiceId) {
    switch (serviceId) {
        // whatsapp
        case 14: {
            // Launch Facebook login
            window?.FB.login(async function (response) {
                if (response.authResponse) {
                    console.log(response.authResponse)
                    const signupToken = response.authResponse.code;
                    //Use this token to call the debug_token API and get the shared WABA's ID
                    await updateService(userServiceId, { status: 1, config: { signup_code: signupToken } });
                    window.location.reload();
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            }, {
                config_id: '866845535127446', // configuration ID obtained in the previous step goes here
                response_type: 'code',     // must be set to 'code' for System User access token
                override_default_response_type: true,
                extras: {
                    setup: {
                        sessionInfoVersion: 2,  //  Receive Session Logging Info
                    }
                }
            });
        }
        default: {
            return;
        }
    }
}

export function generateServiceSdkButton(serviceId, { title, ...restProps }) {
    switch (serviceId) {
        // whatsapp
        case 14: {
            return (
                <button
                    style={{
                        backgroundColor: '#1877f2',
                        border: 0,
                        borderRadius: '4px',
                        color: '#fff',
                        cursor: 'pointer',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        height: '40px',
                        padding: '0 24px'
                    }}
                    {...restProps}
                >
                    {title ?? 'Continue with Facebook'}
                </button>
            )
        }
        default: {
            return;
        }
    }
}