import { Button, Table, Tag, Typography, Input } from 'antd';
import TableBar from 'components/TableBar';
import dayjs from 'dayjs';
import { parse, stringify } from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { FaRedo } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toast';
import { getCampaignLogs, resendWhatsappMessage } from 'requests/notification.js';

const { Search } = Input;

export default function CampaignLogs() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentQuery, setCurrentQuery] = useState({
        page: 1,
        per_page: 10,
    });

    // const enumMessageTypes = useSelector((state) => state.config.notification_message_types);
    const enumStatuses = useSelector((state) => state.config.notification_statuses);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [statuses, statusColors] = useMemo(() => {
        const statuses = {};
        const statusColors = {};
        for (const status of enumStatuses) {
            statuses[status.value] = status.display;
            statusColors[status.value] = status.color;
        }
        return [statuses, statusColors];
    }, [enumStatuses]);

    useEffect(() => {
        if (params.id) {
            getData();
        }
    }, [location.pathname, location.search, params.id]);

    const getData = async () => {
        try {
            setLoading(true);

            const query = parse(location.search.slice(1));
            setCurrentQuery(query);

            const data = await getCampaignLogs(params.id, query);
            setData(data);
        } catch (err) {
            toast.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleQuery = (newQuery) => {
        const query = {
            ...currentQuery,
            ...newQuery,
        };

        navigate(
            {
                pathname: location.pathname,
                search: stringify(query),
            },
            { replace: true },
        );
    };

    const handleSearchQuery = (keyword) => {
        handleQuery({ keyword, page: 1 });
    };

    const handlePageChange = (page, per_page) => {
        handleQuery({ page, per_page });
    };

    const handleResendWhatsappMessage = async (id) => {
        try {
            setLoading(true);
            await resendWhatsappMessage(id);
            toast.success('Success');
            await getData();
        } catch (err) {
            toast.error(err.response?.data.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Sending number',
            dataIndex: ['wa_sending_number', 'display_phone_number'],
            render: (number) => <span className="nowrap">{number}</span>,
        },
        {
            title: 'Receive number',
            dataIndex: 'receive_number',
        },
        {
            title: 'Sent at',
            dataIndex: 'sent_at',
            render: (time) => (
                <div>
                    <span className="nowrap">{dayjs(time).format('YYYY-MM-DD')}</span>
                    <span> </span>
                    <span className="nowrap">{dayjs(time).format('HH:mm')}</span>
                </div>
            ),
        },
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            render: (time) => (
                <div>
                    <span className="nowrap">{dayjs(time).format('YYYY-MM-DD')}</span>
                    <span> </span>
                    <span className="nowrap">{dayjs(time).format('HH:mm')}</span>
                </div>
            ),
        },
        {
            title: 'Cost',
            dataIndex: ['details', 'credit_cost'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (value, record) => (
                <Tag title={record.details?.error_message || record.wam_id} color={statusColors[value]}>
                    {statuses[value] || value}
                </Tag>
            ),
        },
        {
            title: 'Action',
            render: (record, index) => (
                <div className="action">
                    <Button onClick={() => handleResendWhatsappMessage(record.id)}>
                        <FaRedo />
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="sent-message-logs">
            <Typography.Title level={3}>Campaign#{params.id}</Typography.Title>
            <TableBar onSearch={handleSearchQuery} showFilter={false} />
            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={data.records}
                expandable={{
                    expandedRowRender: (record) => (
                        <ReactJson className="details-viewer" src={record.details} name="details" collapsed={false} />
                    ),
                }}
                pagination={{
                    current: data.page,
                    pageSize: data.per_page,
                    total: data.total_records,
                    showSizeChanger: true,
                    onChange: handlePageChange,
                }}
            />
        </div>
    );
}
