import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Layout, Menu, Badge, Button, Modal, Row } from 'antd';
// icons
import { BellIcon, UserIcon, PlusIcon, MenuIcon } from '@heroicons/react/outline';
import { Logout as LogoutIcon } from 'react-iconly';
// requests
import { logoutAction as logout } from 'redux/actions/auth';

const { Header } = Layout;
const { confirm } = Modal;

const DropdownContent = () => (
	<Menu>
		<Menu.Item>1st menu item</Menu.Item>
		<Menu.Item>2nd menu item</Menu.Item>
		<Menu.Item>3rd menu item</Menu.Item>
	</Menu>
);

const DropdownProfileContent = ({ onLogout }) => (
	<Menu>
		<Menu.Item>
			<Button type="link">Change Password</Button>
		</Menu.Item>
		<Menu.Item>
			<Button type="link" danger onClick={onLogout}>
				Log out
			</Button>
		</Menu.Item>
	</Menu>
)

const AppHeader = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onLogout = () => {
		confirm({
			title: 'Are you sure you want to logout?',
			onOk: () => { 
				dispatch(logout());
				navigate('/signin');
			}
		});
	}

	return (
		<Header className="app-header">
			{/* <div className="app-btn-round mr-24">
				<Button type="link" shape="round" size="large" icon={<PlusIcon width={12} height={12} />}>
					Add Credit
				</Button>
			</div>
			<Badge count={5} offset={[0, 10]}>
				<div className="app-circle-icon">
					<Dropdown overlay={DropdownContent} placement="bottomRight">
						<BellIcon width={24} height={24} />
					</Dropdown>
				</div>
			</Badge>
			<div className="app-circle-icon ml-24">
				<Dropdown overlay={<DropdownProfileContent onLogout={onLogout} />} placement="bottomRight">
					<UserIcon width={24} height={24} />
				</Dropdown>
			</div> */}
			<Row>
				<a href="#app-sidebar">
					<MenuIcon className="menu-icon" width={24} height={24} />
				</a>
			</Row>
			<Row className='link' align='middle' onClick={onLogout}>
				<LogoutIcon set="light" />
				<span className="ml-8"><b>Logout</b></span>
			</Row>
		</Header>
	);
};

export default AppHeader;
