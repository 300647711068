import React, { useEffect, useState } from 'react';
import { Table, Switch, Input, Row, Col, Checkbox } from 'antd';
import { RiDragMoveLine } from 'react-icons/ri';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext } from '@dnd-kit/core';
import _ from 'lodash';
import UploadButton from 'components/UploadButton';
import { BaseSelect } from 'components/Elements';
import { useSelector } from 'react-redux';

const DraggableRow = ({ children, ...props }) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if (child.key === 'drag') {
                    return React.cloneElement(child, {
                        children: (
                            <RiDragMoveLine
                                className="drag-icon"
                                size={28}
                                style={{
                                    touchAction: 'none',
                                    cursor: 'move',
                                }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

function PaymentGateway(props) {
    const { platform, defaultGateway, gateways, onUpdateData } = props;
    const config = useSelector(state => state.config);

    const columns = [
        {
            title: 'Payment gateway',
            width: 200,
            render: (text, record) => (
                <strong>{record.name}</strong>
            )
        },
        {
            title: 'Display name',
            render: (text, record) => (
                <Input defaultValue={record.display_name} onChange={(e) => onUpdateGateway(record.id, 'display_name', e.target.value)} />
            )
        },
        {
            title: 'Payment modes',
            render: (text, record) => {
                if (Array.isArray(record.modes) && record.customize_modes) {
                    const supportedModes = config?.payment_modes.filter(mode => record.modes.includes(mode.slug));
                    const options = supportedModes.map(mode => ({ label: mode.display, value: mode.value }));
                    const defaultValues = record.supported_modes ?? supportedModes.map(mode => mode.value);

                    // if there is only one option, then user should use toggle status button instead
                    if (options.length > 1) {
                        return (
                            <Checkbox.Group 
                                options={options} 
                                defaultValue={defaultValues} 
                                className="checkbox-vertical" 
                                onChange={(values) => onUpdateGateway(record.id, 'supported_modes', values)}
                            />
                        );
                    }

                    return null;
                }

                return null;
            }
        },
        {
            title: 'Custom icon',
            render: (text, record) => (
                <UploadButton
                    preview={true}
                    defaultFilePath={record.icon}
                    onChange={(uploadedPath) => onUpdateGateway(record.id, 'icon', uploadedPath)}
                />
            )
        },
        {
            title: 'Status',
            render: (text, record) => (
                <Switch
                    defaultChecked={record.status}
                    onChange={(checked) => onUpdateGateway(record.id, 'status', Number(checked))}
                />
            ),
        },
        {
            key: 'drag',
        },
    ];
    const [items, setItems] = useState([]);

    useEffect(() => {
        let updatedGateway = {};
        let originItems = Object.keys(gateways).map((key, idx) => {
            let item = {
                id: key,
                index: idx,
                name: gateways[key].name,
                display_name: gateways[key].display_name ?? gateways[key].name,
                status: !!gateways[key].status,
                rank: gateways[key].rank ?? idx + 1,
                icon: gateways[key].icon ?? '',
                modes: gateways[key].modes,
                supported_modes: gateways[key].supported_modes || null,
                customize_modes: gateways[key].customize_modes
            };

            updatedGateway[key] = item;

            return item;
        });
        originItems = _.sortBy(originItems, 'rank');

        // update default rank (it will solve error rank is null or duplicated rank in case new gateway is added)
        if (!items.length && platform) {
            onUpdateData(`platform.${platform}.gateway`, updatedGateway);
        }

        setItems(originItems);
    }, [gateways]);

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            const activeIndex = items.findIndex((i) => i.index === active.id);
            const overIndex = items.findIndex((i) => i.index === over?.id);
            let newItems = arrayMove(items, activeIndex, overIndex);

            let result = {};
            newItems.forEach((item, idx) => {
                item.rank = idx + 1;
                result[item.id] = item;
            });

            onUpdateData(`platform.${platform}.gateway`, result);
        }
    };

    const onUpdateGateway = _.debounce((id, name, value) => {
        onUpdateData(`platform.${platform}.gateway.${id}.${name}`, value);
    }, 500);

    return (
        <div>
            <Row className='mb-16' gutter={[32, 32]}>
                <Col xs={24} sm={12} md={6} lg={6}>
                    <div className='mb-8'>Default payment gateway</div>
                    <BaseSelect
                        className='w-100'
                        options={items}
                        optionLabel='name'
                        optionValue='id'
                        value={defaultGateway}
                        onChange={(value) => onUpdateData(`platform.${platform}.default_payment_gateway`, value)}
                    />
                </Col>
            </Row>
            <DndContext onDragEnd={onDragEnd} >
                <SortableContext
                    // rowKey array
                    items={items.map((i) => i.index)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: DraggableRow,
                            },
                        }}
                        rowKey={'index'}
                        dataSource={items}
                        columns={columns}
                        pagination={false}
                    />
                </SortableContext>
            </DndContext>
        </div>
    );
}

PaymentGateway.propTypes = {};

export default PaymentGateway;
