import { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Row, Col } from 'antd';
import { BaseSelect } from 'components/Elements';
// request
import { getCountries, getStates } from 'requests/country';

const WarehouseCreateForm = ({ isOpen = false, title = '', defaultValues = {}, onSubmit, onClose }) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formRef] = Form.useForm();

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (!isOpen) formRef.resetFields();
    }, [isOpen]);

    const fetchCountries = async () => {
        // country
        const countryResponse = await getCountries({ is_paginate: 0 }); // get all countries
        setCountries(countryResponse.records);
    }

    const fetchStates = async (country_id) => {
        formRef.setFieldsValue({ state_id: '' });

        // get states
        const stateResponse = await getStates(country_id, { is_paginate: 0 });
        setStates(stateResponse.records);
    }

    const handleSubmit = async (formData) => {
        try {
            setLoading(true);
            await onSubmit(formData);
            onClose();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            visible={isOpen}
            title={title}
            footer={null}
            width={720}
            onCancel={onClose}
        >
            <Form
                form={formRef}
                // key={Date.now()}
                initialValues={defaultValues}
                layout='vertical'
                onFinish={handleSubmit}
            >
                <Form.Item name='title' label='Title' rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Row gutter={32}>
                    <Col sm={24} md={12}>
                        <Form.Item name='owner_name' label='Owner Name' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}>
                        <Form.Item name='owner_phone' label='Owner Phone' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name='address' label='Address' rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Row gutter={32}>
                    <Col sm={24} md={12}>
                        <Form.Item name='city' label='City' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col sm={24} md={12}>
                        <Form.Item name='pincode' label='Pincode' rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="country_id" label="Country" rules={[{ required: true }]}>
                    <BaseSelect
                        options={countries}
                        optionLabel="name"
                        optionValue="id"
                        defaultText="Select one"
                        showSearch
                        onChange={fetchStates}
                    />
                </Form.Item>
                <Form.Item name="state_id" label="State" rules={[{ required: true }]}>
                    <BaseSelect
                        options={states}
                        optionLabel="name"
                        optionValue="id"
                        defaultText="Select one"
                        showSearch
                    />
                </Form.Item>
                <Row justify="end">
                    <Button type='primary' htmlType='submit' loading={loading}>Submit</Button>
                </Row>
            </Form>
        </Modal>
    )
}

export default WarehouseCreateForm;
