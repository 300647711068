import { Row, Col, Switch, Input } from 'antd';
import { BaseSelect } from 'components/Elements';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const BackendSettingForm = (props) => {
    const { platform, data, autoBookingModes, failoverAutoBookingModes, orderTypes, modes, services, warehouses, onChange } = props;

    const config = useSelector((state) => state.config);

    const [selectedServiceId, setSelectedServiceId] = useState(data?.default_service_id || null);

    const warehouseOptions = useMemo(() => {
        let selectedWarehouses = [];

        if (selectedServiceId) {
            selectedWarehouses = warehouses[selectedServiceId];
        }

        return selectedWarehouses ?? [];
    }, [selectedServiceId]);

    const onChangeService = (value) => {
        onChange(`${platform}.backend.default_service_id`, value);
        setSelectedServiceId(value);
    };

    return (
        <div>
            <Row gutter={[48, 16]} className="mb-16">
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Row justify="space-between" align="middle">
                        <div>Auto add new orders to Shipment orders</div>
                        <Switch
                            checked={data?.auto_add_to_shipment}
                            onChange={(checked) => onChange(`${platform}.backend.auto_add_to_shipment`, checked)}
                        />
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Row justify="space-between" align="middle">
                        <div>Auto book when order is marked as Shipped</div>
                        <Switch
                            checked={data?.auto_booking}
                            onChange={(checked) => onChange(`${platform}.backend.auto_booking`, checked)}
                        />
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Row justify="space-between" align="middle">
                        <div>Combine name and company name</div>
                        <Switch
                            checked={data?.combine_name_and_company_name}
                            onChange={(checked) =>
                                onChange(`${platform}.backend.combine_name_and_company_name`, checked)
                            }
                        />
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Row justify="space-between" align="middle">
                        <div>Phone number mandatory check</div>
                        <Row align="middle">
                            <Input
                                placeholder="Specify digits"
                                size="small"
                                className="mr-16"
                                value={data?.phone_digit_numbers}
                                onChange={(e) => onChange(`${platform}.backend.phone_digit_numbers`, e.target.value)}
                                style={{ width: 120 }}
                            />
                            <Switch
                                checked={data?.enable_check_phone}
                                onChange={(checked) => onChange(`${platform}.backend.enable_check_phone`, checked)}
                            />
                        </Row>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-16" gutter={[48, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default item weight</div>
                    <Input
                        addonAfter="kg"
                        value={data?.default_item_weight}
                        onChange={(e) => onChange(`${platform}.backend.default_item_weight`, e.target.value)}
                    />
                    <p>
                        <small>
                            The default item weight is used to calculate shipping rates for products with no weight
                            specified. To precisely calculate shipping costs for all of your products, please specify
                            their weight in product details.
                        </small>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default package dimensions</div>
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Input
                                addonAfter="cm"
                                placeholder="Length"
                                value={data?.default_item_length}
                                onChange={(e) => onChange(`${platform}.backend.default_item_length`, e.target.value)}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Input
                                addonAfter="cm"
                                placeholder="Breadth"
                                value={data?.default_item_breadth}
                                onChange={(e) => onChange(`${platform}.backend.default_item_breadth`, e.target.value)}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Input
                                addonAfter="cm"
                                placeholder="Height"
                                value={data?.default_item_height}
                                onChange={(e) => onChange(`${platform}.backend.default_item_height`, e.target.value)}
                            />
                        </Col>
                    </Row>
                    <p>
                        <small>
                            The default package dimensions is used to calculate shipping rates for products with no
                            dimensions specified. To precisely calculate shipping costs for all of your products, please
                            specify their dimensions in product details.
                        </small>
                    </p>
                </Col>
            </Row>
            <Row className="mb-16" gutter={[32, 32]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default order type</div>
                    <BaseSelect
                        className="w-100"
                        options={orderTypes}
                        value={data?.default_order_type || ''}
                        onChange={(value) => onChange(`${platform}.backend.default_order_type`, value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default mode type</div>
                    <BaseSelect
                        className="w-100"
                        options={modes}
                        value={data?.default_mode || ''}
                        onChange={(value) => onChange(`${platform}.backend.default_mode`, value)}
                    />
                </Col>
            </Row>
            <Row className="mb-16" gutter={[48, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default Service</div>
                    <BaseSelect
                        className="w-100"
                        options={services}
                        value={data?.default_service_id || ''}
                        optionLabel="name"
                        optionValue="id"
                        onChange={onChangeService}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default warehouse</div>
                    <BaseSelect
                        className="w-100"
                        options={warehouseOptions}
                        value={data?.default_warehouse_id}
                        onChange={(value) => onChange(`${platform}.backend.default_warehouse_id`, value)}
                    />
                </Col>
            </Row>
            <Row className="mb-16" gutter={[48, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Default Carrier</div>
                    <BaseSelect
                        className="w-100"
                        options={config.carriers}
                        value={data?.default_carrier_id ?? ''}
                        optionLabel="display"
                        onChange={(value) => onChange(`${platform}.backend.default_carrier_id`, value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Reduce COD order value while booking</div>
                    <Input
                        addonBefore={'₹'}
                        placeholder="Amount"
                        value={data?.reduce_order_amount}
                        onChange={(e) => onChange(`${platform}.backend.reduce_order_amount`, e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="mb-16" gutter={[48, 16]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className="mb-8">Auto booking mode</div>
                    <BaseSelect
                        className="w-100"
                        options={autoBookingModes}
                        value={data?.auto_booking_mode}
                        onChange={(value) => onChange(`${platform}.backend.auto_booking_mode`, value)}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <div className='mb-8'>Failover option for customer selection carrier</div>
                    <BaseSelect
                        className="w-100"
                        options={failoverAutoBookingModes}
                        value={data?.failover_auto_booking_mode}
                        onChange={(value) => onChange(`${platform}.backend.failover_auto_booking_mode`, value)}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BackendSettingForm;
