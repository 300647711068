import { toast } from 'react-toast';
import api from 'utils/api';

export const verifyDomain = (data) => {
    return new Promise((resolve, reject) => {
        return api.post('/merchant/domain/verify', data).then(response => {
            toast.success('Your domain is verified');
            resolve(response.data);
        }).catch(err => {
            toast.error(err.response.data.message);
            reject(err);
        });
    })
}

export const removeDomain = () => {
    return new Promise((resolve, reject) => {
        return api.delete('/merchant/domain/remove').then(response => {
            toast.success('Your domain is removed');
            resolve(response.data);
        }).catch(err => {
            toast.error(err.response.data.message);
            reject(err);
        });
    })
}