import api from 'utils/api';

export const getTransactions = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/merchant/transactions', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateTransaction = (id, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/partner/transactions/${id}`, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};